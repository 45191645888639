import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-component flex align-center" }
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = { class: "flex-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchIcon = _resolveComponent("SearchIcon")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_UserMessageComponent = _resolveComponent("UserMessageComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          ref: "searchTermInput",
          placeholder: _ctx.placeholder,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
          onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onSearch && _ctx.onSearch(...args)), ["enter"]))
        }, null, 40, _hoisted_3), [
          [_vModelText, _ctx.searchTerm]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ButtonComponent, {
          class: "search-button",
          onClick: _ctx.onSearch
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SearchIcon)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    (_ctx.hasUserMessage)
      ? (_openBlock(), _createBlock(_component_UserMessageComponent, {
          key: 0,
          message: _ctx.onSearchMessage
        }, null, 8, ["message"]))
      : _createCommentVNode("", true)
  ], 64))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "checkbox-component flex align-center" }
const _hoisted_2 = ["id", "checked"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TickIcon = _resolveComponent("TickIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      ref: "checkboxInput",
      id: _ctx.id,
      type: "checkbox",
      checked: _ctx.modelValue,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.update && _ctx.update(...args)))
    }, null, 40, _hoisted_2),
    _createElementVNode("label", {
      for: _ctx.id,
      class: "checkbox-label"
    }, [
      (_ctx.modelValue)
        ? (_openBlock(), _createBlock(_component_TickIcon, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
    ], 8, _hoisted_3)
  ]))
}
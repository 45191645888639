
import { computed, defineComponent, PropType } from 'vue';
import { useRouter } from 'vue-router';

import CheckBoxComponent from '@/component/item/CheckBoxComponent.vue';
import AreYouSureModalContentComponent, { AreYouSureModalContentComponentProps } from '@/component/modal/content/AreYouSureModalContentComponent.vue';
import BinIcon from '@/component/icon/BinIcon.vue';
import CrossIcon from '@/component/icon/CrossIcon.vue';
import CutleryIcon from '@/component/icon/CutleryIcon.vue';
import PlusIcon from '@/component/icon/PlusIcon.vue';

import { useCurrentProduct } from '@/use/state/CurrentProduct.use';
import { UseShoppingListChecked, UseForSeachChecked } from '@/use/ProductChecked.use';
import { useShoppingList } from '@/use/state/ShoppingList.use';

import { Product } from '@/model/Product.model';
import { Event, eventService } from '@/service/Event.service';

interface ProductComponentProps {
    product: Product,
    forSearch: Boolean,
}

export default defineComponent({
    name: 'ProductComponent',

    components: {
        CheckBoxComponent,
        AreYouSureModalContentComponent,
        BinIcon,
        CrossIcon,
        CutleryIcon,
        PlusIcon,
    },

    props: {
        product: {
            type: Object as PropType<Product>,
            required: true,
        },
        forSearch: Boolean,
    },

    setup(props: ProductComponentProps) {
        const router = useRouter();
        const currentProduct = useCurrentProduct();
        const shoppingList = useShoppingList();

        const isChecked = props.forSearch
            ? UseForSeachChecked(props.product).isChecked
            : UseShoppingListChecked(props.product).isChecked;

        const displayPrice = computed<string>(
            () => `£${props.product.price.toFixed(2)}`);

        return {
            isChecked,
            displayPrice,

            onSelect() {
                if (props.product.isFreetext)
                    return;

                currentProduct.product.value = props.product;
                router.push({ path: '/product', });
            },

            onRemove() {
                eventService.publish(Event.OPEN_MODAL, {
                    content: AreYouSureModalContentComponent,
                    props: <AreYouSureModalContentComponentProps>{
                        message: `Remove <strong>${props.product.name}</strong> from your shopping list?`,
                        yesMessage: 'Delete Freetext',
                        noMessage: 'Cancel',
                        yesAction() {
                            shoppingList.remove(props.product);
                        },
                        noAction() {},
                    },
                });
            },
        }
    },
})

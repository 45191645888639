
import { useIcon, useIconProps } from '@/use/Icon.use';

export default {
    name: 'PencilIcon',

    props: useIconProps(),

    setup(props: any) {
        return useIcon(props);
    },
}

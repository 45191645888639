
import { computed, defineComponent, onMounted, readonly, ref } from 'vue';

import PageContainerComponent from '@/component/PageContainerComponent.vue';
import LoadingComponent from '@/component/LoadingComponent.vue';
import EditIcon from '@/component/icon/PencilIcon.vue';
import ShopIcon from '@/component/icon/ShopIcon.vue';

import { mapApi } from '@/api/map/Map.api';
import { useSelectedShop } from '@/use/state/SelectedShop.use';
import { useScrollPosition } from '@/use/ScrollPosition.use';
import { useUserMessage } from '@/use/UserMessage.use';

import { ShopOpeningHourHours } from '@/model/Shop.model';

export default defineComponent({
    name: 'ShopView',

    components: {
        PageContainerComponent,
        LoadingComponent,
        EditIcon,
        ShopIcon,
    },

    setup() {
        useScrollPosition('ShopView');
        const selectedShop = useSelectedShop();
        const userMessage = useUserMessage();

        const shop = selectedShop.shop;

        const allowedFacilities = readonly<Record<string, string>>({
            'ATM': 'ATM',
            'Cafe': 'Cafe',
            'Clothing Range': 'Clothing Range',
            'Customer Recycling Point': 'Recycling Point',
            'Disabled parking facilities are available': 'Disabled Parking',
            'Facilities are available for people who are visually impaired. Including large print and braille.': 'Visually Impaired Facilities',
            'Facilities are available for people with hearing impairments.': 'Hearing Impaired Facilities',
            'Facilities are available for people with mobility impairment.': 'Mobility Impaired Facilities',
            'Jet Wash': 'Jet Wash',
            'Hand Car Wash': 'Hand Car Wash',
            'Petrol': 'Petrol',
            'Photo Booth - Digital Passport Enabled': 'Photo Booth',
            'Scan as you Shop': 'Scan as you Shop',
            'Tesco Mobile Shop': 'Tesco Mobile Shop',
            'Tesco Pay+': 'Tesco Pay+',
            'Toilets': 'Toilets',
            'Wifi': 'Wifi',
            'Collect+': 'Collect+',
            'Self-Service Instant Photo Printing': 'Self-Service Instant Photo Printing',
            'Pharmacy': 'Pharmacy',
            'Timpson': 'Timpson',
        });

        const displayFacilities = computed<string[]>(() => {
            if (shop.value === null || shop.value.facilities === null)
                return [];

            return shop.value.facilities
                .map(f => allowedFacilities[f])
                .filter(f => f !== undefined)
                .sort((a: string, b: string) => {
                    if(a < b) return -1;
                    if(a > b) return 1;
                    return 0;
                });
        });

        const formatOpeningHour = (openingHour: string): string => {
            const hour = Number(`${openingHour[0]}${openingHour[1]}`);
            const minutes = Number(`${openingHour[2]}${openingHour[3]}`);

            if (hour === 0 || hour === 24) {
                return 'Midnight';
            }

            const hourPostfix = (hour > 12) ? 'pm' : 'am';
            const hour12Hour = (hour > 12) ? hour - 12 : hour;

            const minutesFormatted = minutes.toString().padStart(2, '0');

            if (minutes === 0) {
                return `${hour12Hour}${hourPostfix}`;
            }

            return `${hour12Hour}:${minutesFormatted}${hourPostfix}`;
        };

        const previewImageErrorMessage = ref<string>('');

        onMounted(async () => {
            if (shop.value == null)
                return;
            if (shop.value.location.mapImage)
                return;

            const { longitude, latitude } = shop.value.location;

            const previewImage = await mapApi.getStaticMap(longitude, latitude);
            if (previewImage instanceof Error) {
                userMessage.set(previewImageErrorMessage, previewImage.message);
                return;
            }

            shop.value.location.mapImage = previewImage;
        });

        return {
            shop,
            displayFacilities,

            getOpeningHour(openingHour: ShopOpeningHourHours): string {
                if (!openingHour) {
                    return 'No data';
                }
                if (!openingHour.isOpen) {
                    return 'Closed';
                }

                if (!openingHour.open && !openingHour.close
                    || openingHour.open === '0000' && !openingHour.close
                    || !openingHour.open && openingHour.close === '2400') {
                    return '24 Hours';
                }

                const open = formatOpeningHour(!openingHour.open ? '0000' : openingHour.open);
                const close = formatOpeningHour(!openingHour.close ? '0000' : openingHour.close);

                return `${open} - ${close}`;
            },
        }
    },
})

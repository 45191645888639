
import { computed, defineComponent, readonly, ref } from 'vue';
import { useRouter } from 'vue-router';
import { VueDraggableNext as VueDraggable } from 'vue-draggable-next';

import PageContainerComponent from '@/component/PageContainerComponent.vue';
import ProductComponent from '@/component/ProductComponent.vue';
import ButtonComponent from '@/component/item/ButtonComponent.vue';
import SearchComponent from '@/component/SearchComponent.vue';
import AddFreeTextModalContentComponent from '@/component/modal/content/AddFreeTextModalContentComponent.vue';
import BasketIcon from '@/component/icon/BasketIcon.vue';
import ChevronDownIcon from '@/component/icon/ChevronDownIcon.vue';
import ChevronUpIcon from '@/component/icon/ChevronUpIcon.vue';
import AddIcon from '@/component/icon/PlusIcon.vue';

import { useShoppingList } from '@/use/state/ShoppingList.use';
import { useScrollPosition } from '@/use/ScrollPosition.use';

import { Product } from '@/model/Product.model';
import { Event, eventService } from '@/service/Event.service';

export default defineComponent({
    name: 'ShoppingListView',

    components: {
        VueDraggable,
        PageContainerComponent,
        ProductComponent,
        ButtonComponent,
        SearchComponent,
        BasketIcon,
        ChevronDownIcon,
        ChevronUpIcon,
        AddIcon,
    },

    setup() {
        useScrollPosition('ShoppingListView');
        const router = useRouter();

        const {
            products,
            checked,
            settings,
            ...shoppingList
        } = useShoppingList();

        const searchTextbox = ref<HTMLInputElement | null>(null);
        const checkedSection = ref<HTMLElement | null>(null);

        const checkedSearchTerm = ref<string>('');

        const draggableOptions = readonly({
            delay: 300,
            animation: 0,
            disabled: false,
            ghostClass: 'is-dragged',
        });

        const isCheckedProductsVisible = computed<boolean>(() => settings.value.isCheckedProductsVisible);

        const unchecked = computed<Array<string>>({
            get() {
                return shoppingList.unchecked.value;
            },
            set(ids: Array<string>) {
                shoppingList.unchecked.value = ids;
            },
        });

        const displayChecked = computed<Array<string>>(() => {
            return checked.value
                .filter(x => products.value[x].name.toLowerCase().indexOf(checkedSearchTerm.value.toLowerCase()) > -1)
                .sort((a, b) => {
                    const productA = products.value[a];
                    const productB = products.value[b];

                    if (productA.addCount < productB.addCount) return 1;
                    if (productA.addCount > productB.addCount) return -1;
                    return 0;
                });
        });

        const isShoppingListEmpty = computed<boolean>(() => unchecked.value.length === 0 && checked.value.length === 0);

        const totalPrice = computed<number>(() => unchecked.value
            .map(x => products.value[x])
            .reduce((total, product) => total + (product.price * product.listQuantity), 0.0));

        const displayTotalPrice = computed<string>(() => {
            if (isShoppingListEmpty.value)
                return 'Empty!';

            return `£${totalPrice.value.toFixed(2)}`
        });

        const firstProductSearchTerm = ref<string>('');
        const firstProductUserMessage = ref<string | null>(null);

        return {
            searchTextbox,
            checkedSection,
            checkedSearchTerm,
            draggableOptions,
            isCheckedProductsVisible,
            products,
            unchecked,
            checked,
            displayChecked,
            displayTotalPrice,
            isShoppingListEmpty,
            firstProductSearchTerm,
            firstProductUserMessage,

            onCheckedTextBoxFocus() {
                if (checkedSection.value === null)
                    return;

                window.scrollTo(0, checkedSection.value.offsetTop);
            },

            onClearCheckedSearch() {
                checkedSearchTerm.value = '';
                searchTextbox.value?.focus();
            },

            toggleCheckedItemsVisibility() {
                settings.value.isCheckedProductsVisible = !settings.value.isCheckedProductsVisible;
            },

            onFirstProductSearch(searchTerm: string) {
                router.push({
                    name: 'product_search',
                    params: {
                        prePopulatedSearchTerm: searchTerm,
                    },
                });
            },

            onAddFreetext() {
                eventService.publish(Event.OPEN_MODAL, {
                    content: AddFreeTextModalContentComponent,
                });
            },
        }
    },
})

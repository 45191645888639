import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "user-message-component flex align-center"
}
const _hoisted_2 = { class: "message text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionMarkCircleIcon = _resolveComponent("QuestionMarkCircleIcon")!

  return (_ctx.message.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_QuestionMarkCircleIcon),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.message), 1)
      ]))
    : _createCommentVNode("", true)
}

import { defineComponent } from 'vue';

import QuestionMarkCircleIcon from '@/component/icon/QuestionMarkCircleIcon.vue';

export default defineComponent({
    name: 'UserMessageComponent',

    components: {
        QuestionMarkCircleIcon,
    },

    props: {
        message: {
            type: String,
            required: true,
        },
    },
})

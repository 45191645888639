
import { defineComponent, ref } from 'vue';

import ButtonComponent from '@/component/item/ButtonComponent.vue';
import UserMessageComponent from '@/component/item/UserMessageComponent.vue';
import SearchIcon from '@/component/icon/SearchIcon.vue';

import { useUserMessage } from '@/use/UserMessage.use';

export default defineComponent({
    name: 'SearchComponent',

    props: {
        prePopulatedSearchTerm: {
            type: String,
            required: false,
            default: '',
        },
        hasUserMessage: {
            type: Boolean,
            required: false,
            default: false,
        },
        placeholder: {
            type: String,
            required: false,
        },
    },

    emits: [
        'search',
    ],

    components: {
        ButtonComponent,
        UserMessageComponent,
        SearchIcon,
    },

    setup(props, { emit }) {
        const userMessage = useUserMessage();

        const searchTermInput = ref<HTMLInputElement | null>(null);

        const onSearchMessage = ref<string>('');
        const searchTerm = ref<string>(props.prePopulatedSearchTerm);

        return {
            searchTermInput,
            onSearchMessage,
            searchTerm,

            onSearch() {
                if (searchTerm.value.length < 3) {
                    userMessage.set(onSearchMessage, 'Search term is too short, please try another.');
                    return;
                }

                emit('search', searchTerm.value);
            },

            focus() {
                searchTermInput.value?.focus();
            },

            blur() {
                searchTermInput.value?.blur();
            },
        }
    },
})

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { role: "navigation" }
const _hoisted_2 = {
  key: 0,
  class: "expanded-navigation content-width"
}
const _hoisted_3 = { class: "pages-container content-width flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", _hoisted_1, [
      (_ctx.isExpanded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.expandedPages, (expandedPage, index) => {
              return (_openBlock(), _createBlock(_component_router_link, {
                class: _normalizeClass({ 'is-current': _ctx.currentPage == expandedPage.link }),
                key: `expanded-page-${index}`,
                to: expandedPage.link
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(expandedPage.icon))),
                    _createElementVNode("span", null, _toDisplayString(expandedPage.title), 1)
                  ])
                ]),
                _: 2
              }, 1032, ["class", "to"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dashboardPages, (dashboardPage, index) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            class: _normalizeClass(["flex-1", { 'is-current': _ctx.currentPage == dashboardPage.link }]),
            key: `dashboard-page-${index}`,
            to: dashboardPage.link
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(dashboardPage.icon))),
              _createElementVNode("div", null, _toDisplayString(dashboardPage.title), 1)
            ]),
            _: 2
          }, 1032, ["class", "to"]))
        }), 128)),
        _createElementVNode("div", {
          class: "current-page",
          style: _normalizeStyle(_ctx.currentPageIndicatorStyle)
        }, null, 4)
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["expanded-nav-backdrop", { 'is-visible': _ctx.isExpanded }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeExpanded && _ctx.closeExpanded(...args)))
    }, null, 2)
  ], 64))
}
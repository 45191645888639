
import { defineComponent, ref } from 'vue';

import TickIcon from '@/component/icon/TickIcon.vue';

export default defineComponent({
    name: 'CheckBoxComponent',

    components: {
        TickIcon,
    },

    props: {
        id: String,
        label: String,
        modelValue: Boolean,
    },

    setup(_, { emit }) {
        const checkboxInput = ref<HTMLInputElement | null>(null);

        return {
            checkboxInput,

            update(event: Event): void {
                if (!event || !event.target || checkboxInput.value === null)
                    return;

                emit('update:modelValue', checkboxInput.value.checked);
            },
        }
    },
})

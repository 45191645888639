
import { computed, defineComponent, SetupContext } from 'vue';

export default defineComponent({
    name: 'ButtonComponent',

    props: {
        isSecondary: Boolean,
    },

    setup(props: any, context: SetupContext) {
        const elementClass = computed(() => ({
            'is-secondary': props.isSecondary,
        }));

        return {
            elementClass,
        }
    },
})

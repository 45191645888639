
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ModalBackdropComponent',

    emits: [
        'close',
    ],

    props: {
        isVisible: Boolean,
        zIndex: {
            type: Number,
            required: false,
            default: 11,
        },
    },

    setup(_, { emit }) {
        return {
            onClose() {
                emit('close');
            },
        }
    },
})


import { computed, defineComponent, onBeforeMount, onMounted, readonly, ref, shallowReadonly } from 'vue';

import PageContainerComponent from '@/component/PageContainerComponent.vue';
import LoadingComponent from '@/component/LoadingComponent.vue';
import ProductComponent from '@/component/ProductComponent.vue';
import ButtonComponent from '@/component/item/ButtonComponent.vue';
import SearchComponent from '@/component/SearchComponent.vue';
import QuestionMarkCircleIcon from '@/component/icon/QuestionMarkCircleIcon.vue';
import SearchIcon from '@/component/icon/SearchIcon.vue';
import SortAlphaIcon from '@/component/icon/SortAlphaIcon.vue';
import SortIcon from '@/component/icon/SortIcon.vue';
import SortListIcon from '@/component/icon/SortListIcon.vue';
import SortNumericIcon from '@/component/icon/SortNumericIcon.vue';

import { searchForProductsResponseMapper } from '@/mapper/SearchForProductsResponse.mapper';
import { productsApi } from '@/api/products/Products.api';
import { useScrollPosition } from '@/use/ScrollPosition.use';
import { useShoppingList } from '@/use/state/ShoppingList.use';
import { ProductSearchSettings, useProductSearch } from '@/use/state/ProductSearch.use';

import { Product } from '@/model/Product.model';
import { SortOption, SortOptionType } from '@/model/SortOption.model';

export default defineComponent({
    name: 'ProductSearchView',

    components: {
        PageContainerComponent,
        ButtonComponent,
        SearchComponent,
        LoadingComponent,
        ProductComponent,
        QuestionMarkCircleIcon,
        SearchIcon,
        SortIcon,
    },

    props: {
        prePopulatedSearchTerm: {
            type: String,
            required: false,
            default: '',
        },
    },

    setup(props) {
        useScrollPosition('ProductSearchView');
        const productSearch = useProductSearch();
        const shoppingList = useShoppingList();

        const searchComponent = ref<typeof SearchComponent | null>(null);

        const isSearching = ref<boolean>(false);
        const products = ref<Product[] | null>(null);

        const isSortAndFilterShown = ref<boolean>(false);

        const productSearchSettings = computed<ProductSearchSettings>(() => productSearch.settings.value);

        const searchComponentSearchTerm = props.prePopulatedSearchTerm.length > 0
            ? props.prePopulatedSearchTerm
            : productSearch.searchTerm.value ?? '';

        const currentPage = productSearch.currentPage;
        const haveAllProductsLoaded = productSearch.haveAllProductsLoaded;

        const displaySortOptions = shallowReadonly<SortOption[]>([
            {
                title: 'Alphabetical (A - Z)',
                type: SortOptionType.ALPHABETICAL,
                icon: SortAlphaIcon,
            },
            {
                title: 'Price (Lowest - Highest)',
                type: SortOptionType.PRICE,
                icon: SortNumericIcon,
            },
            {
                title: 'Health (Best - Worst)',
                type: SortOptionType.HEALTH_SCORE,
                icon: SortListIcon,
            },
        ]);

        const sortFunctions = readonly({
            [SortOptionType.ALPHABETICAL](a: Product, b: Product): number {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;

                return 0;
            },
            [SortOptionType.PRICE](a: Product, b: Product): number {
                if (a.price > b.price) return 1;
                if (a.price < b.price) return -1;

                return 0;
            },
            [SortOptionType.HEALTH_SCORE](a: Product, b: Product): number {
                if (!a.healthScore || !b.healthScore)
                    return 0;

                if (a.healthScore < b.healthScore) return 1;
                if (a.healthScore > b.healthScore) return -1;

                return 0;
            },
        });

        const displayProducts = computed<Product[] | null>(() => {
            if (products.value === null)
                return null;

            return products.value
                .sort(sortFunctions[productSearchSettings.value.sortOption]);
        });

        const onSearch = async function (searchTerm: string) {
            if (searchTerm === null)
                return;

            if (searchTerm.trim().length < 3)
                return;

            searchComponent.value?.blur();

            haveAllProductsLoaded.value = false;
            currentPage.value = 1;
            isSearching.value = true;

            const searchProducts = await productsApi.searchForProducts(searchTerm, currentPage.value);

            isSearching.value = false;

            if (searchProducts instanceof Error) {
                products.value = null;

                searchComponent.value?.focus();
            }
            else {
                products.value = searchForProductsResponseMapper.map(searchProducts);

                if (products.value.length === 0) {
                    searchComponent.value?.focus();
                }

                productSearch.searchTerm.value = searchTerm;
                productSearch.products.value = products.value;
            }
        };

        onBeforeMount(() => {
            const existingProducts = productSearch.products.value;
            if (existingProducts !== null)
                products.value = existingProducts;
        });

        onMounted(() => {
            if (props.prePopulatedSearchTerm.length > 0)
                onSearch(props.prePopulatedSearchTerm);

            if (searchComponentSearchTerm.length === 0)
                searchComponent.value?.focus();
        });

        return {
            searchComponent,

            searchComponentSearchTerm,
            isSearching,
            displayProducts,

            haveAllProductsLoaded,

            isSortAndFilterShown,
            productSearchSettings,
            displaySortOptions,

            onSearch,

            onSortAndFilterToggle() {
                isSortAndFilterShown.value = !isSortAndFilterShown.value;
            },

            onSortOption(sortOption: SortOptionType) {
                productSearch.settings.value.sortOption = sortOption;
                isSortAndFilterShown.value = false;
            },

            async onLoadMore() {
                if (productSearch.searchTerm.value === null)
                    return;

                currentPage.value++;

                const moreSearchProducts = await productsApi.searchForProducts(productSearch.searchTerm.value, currentPage.value);

                if (moreSearchProducts instanceof Error)
                    return;

                if (products.value === null)
                    return;

                if (moreSearchProducts.length === 0) {
                    haveAllProductsLoaded.value = true;
                    return;
                }

                products.value = products.value.concat(searchForProductsResponseMapper.map(moreSearchProducts));
                productSearch.products.value = products.value;
            },

            onBackToTop() {
                window.scrollTo(0, 0);
            },
        }
    },
})

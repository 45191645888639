
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import LeftArrowIcon from '@/component/icon/ArrowLeftIcon.vue';

export default defineComponent({
    name: 'PageContainerComponent',

    components: {
        LeftArrowIcon,
    },

    props: {
        backButton: Boolean,
    },

    setup() {
        const router = useRouter();

        return {
            onBack() {
                router.back();
            },
        }
    },
})


import { defineComponent, onMounted, ref } from 'vue';

import PageContainerComponent from '@/component/PageContainerComponent.vue';
import LoadingComponent from '@/component/LoadingComponent.vue';
import ShopResultComponent from '@/view/shop-search/component/ShopResult.component.vue';
import ButtonComponent from '@/component/item/ButtonComponent.vue';
import SearchComponent from '@/component/SearchComponent.vue';
import QuestionMarkCircleIcon from '@/component/icon/QuestionMarkCircleIcon.vue';
import SearchIcon from '@/component/icon/SearchIcon.vue';

import { shopsApi } from '@/api/shops/Shops.api';
import { useScrollPosition } from '@/use/ScrollPosition.use';

import { Shop } from '@/model/Shop.model';

export default defineComponent({
    name: 'ShopSearchView',

    components: {
        PageContainerComponent,
        LoadingComponent,
        ShopResultComponent,
        ButtonComponent,
        SearchComponent,
        QuestionMarkCircleIcon,
        SearchIcon,
    },

    setup() {
        useScrollPosition('ShopSearchView');

        const searchComponent = ref<typeof SearchComponent | null>(null);

        const isSearching = ref<boolean>(false);
        const shops = ref<Shop[] | null>(null);

        const openShop = ref<string>('');

        onMounted(() => {
            searchComponent.value?.focus();
        });

        return {
            searchComponent,
            isSearching,
            shops,
            openShop,

            async onSearch(searchTerm: string) {
                if (searchTerm.trim().length < 3)
                    return;

                searchComponent.value?.blur();

                isSearching.value = true;

                const searchShops = await shopsApi.getNearbyShops(searchTerm);

                isSearching.value = false;

                if (searchShops instanceof Error) {
                    shops.value = null;

                    searchComponent.value?.focus();
                }
                else {
                    shops.value = searchShops;

                    if (shops.value.length === 0)
                        searchComponent.value?.focus();
                }
            },

            onOpen(shopName: string) {
                openShop.value = shopName;
            },

            onClose() {
                openShop.value = '';
                searchComponent.value?.focus();
            },
        }
    },
})


import { computed, defineComponent, reactive, ref, shallowReadonly, watch } from 'vue';
import { useRoute } from 'vue-router';

import CogIcon from '@/component/icon/CogIcon.vue';
import ListIcon from '@/component/icon/ListIcon.vue';
import SearchIcon from '@/component/icon/SearchIcon.vue';
import ShopIcon from '@/component/icon/ShopIcon.vue';

interface DashboardPage {
    title: string,
    link: string,
    icon: any,
}

export default defineComponent({
    name: 'DashboardComponent',

    components: {
        CogIcon,
    },

    setup() {
        const route = useRoute();

        const dashboardPages = shallowReadonly<DashboardPage[]>([
            {
                title: 'List',
                link: '/',
                icon: ListIcon,
            },
            {
                title: 'Search',
                link: '/search',
                icon: SearchIcon,
            },
            {
                title: 'Shop',
                link: '/shop',
                icon: ShopIcon,
            },
        ]);

        const expandedPages = shallowReadonly<DashboardPage[]>([]);

        const currentPageIndicator = reactive({
            position: '0px',
            isVisible: true,
        });

        const isExpanded = ref<boolean>(false);

        watch(() => route.path, () => {
            switch (route.path) {
                case '/':
                    currentPageIndicator.isVisible = true;
                    currentPageIndicator.position = '0px';
                    break;
                case '/search':
                    currentPageIndicator.isVisible = true;
                    currentPageIndicator.position = '33.333%';
                    break;
                case '/shop':
                    currentPageIndicator.isVisible = true;
                    currentPageIndicator.position = '66.666%';
                    break;
                default:
                    currentPageIndicator.isVisible = false;
            }

            isExpanded.value = false;
        });

        const currentPageIndicatorStyle = computed(() => ({
            left: currentPageIndicator.position,
            opacity: currentPageIndicator.isVisible ? '1' : '0',
        }));

        const currentPage = computed<string>(() => route.path);

        return {
            isExpanded,
            dashboardPages,
            expandedPages,
            currentPageIndicatorStyle,
            currentPage,

            closeExpanded() {
                isExpanded.value = false;
            },
        }
    },
})


import { defineComponent, watch } from 'vue';

import ModalBackdropComponent from '@/component/modal/ModalBackdropComponent.vue';
import CrossIcon from '@/component/icon/CrossIcon.vue';

export default defineComponent({
    name: 'SideModalContentComponent',

    components: {
        ModalBackdropComponent,
        CrossIcon,
    },

    emits: [
        'open',
        'close',
    ],

    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
    },

    setup(props, { emit }) {
        watch(() => props.isOpen, () => {
            if (!props.isOpen)
                return;

            emit('open');
        });
    },
})

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "loading-component" }
const _hoisted_2 = { class: "logo-container" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoIcon = _resolveComponent("LogoIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LogoIcon, { class: "loading-logo" })
    ]),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex-auto"
}
const _hoisted_2 = {
  key: 1,
  class: "flex-auto"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 2,
  class: "image-placeholder flex flex-auto"
}
const _hoisted_5 = { class: "product-name flex-1" }
const _hoisted_6 = {
  key: 3,
  class: "product-price flex-auto"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 5,
  class: "add-to-list-container flex-auto"
}

export function render(_ctx: any,_cache: any) {
  const _component_CheckBoxComponent = _resolveComponent("CheckBoxComponent")!
  const _component_CutleryIcon = _resolveComponent("CutleryIcon")!
  const _component_CrossIcon = _resolveComponent("CrossIcon")!
  const _component_BinIcon = _resolveComponent("BinIcon")!
  const _component_PlusIcon = _resolveComponent("PlusIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "product-component flex align-center",
    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onSelect && _ctx.onSelect(...args)))
  }, [
    (!_ctx.forSearch)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_CheckBoxComponent, {
            id: `checked-checkbox-${_ctx.product.id}`,
            type: "checkbox",
            modelValue: _ctx.isChecked,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isChecked) = $event)),
            onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
          }, null, 8, ["id", "modelValue"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.product.isFreetext)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            width: "50",
            height: "50",
            class: "product-image",
            src: _ctx.product.imageUrl
          }, null, 8, _hoisted_3)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_CutleryIcon)
        ])),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.product.name), 1),
    (!_ctx.product.isFreetext)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createTextVNode(_toDisplayString(_ctx.displayPrice) + " ", 1),
          (_ctx.product.listQuantity > 1)
            ? (_openBlock(), _createElementBlock("strong", _hoisted_7, " ×" + _toDisplayString(_ctx.product.listQuantity), 1))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: "remove-button flex-auto",
          onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onRemove && _ctx.onRemove(...args)), ["stop"]))
        }, [
          _createVNode(_component_CrossIcon)
        ])),
    (_ctx.forSearch)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", {
            class: "icon-container",
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.isChecked = !_ctx.isChecked), ["stop"]))
          }, [
            (_ctx.isChecked)
              ? (_openBlock(), _createBlock(_component_BinIcon, {
                  key: 0,
                  colour: "url(#bin-grad)"
                }))
              : (_openBlock(), _createBlock(_component_PlusIcon, { key: 1 }))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}

import { computed, defineComponent, PropType, SetupContext } from 'vue';
import { useRouter } from 'vue-router';

import ButtonComponent from '@/component/item/ButtonComponent.vue';
import ShopIcon from '@/component/icon/ShopIcon.vue';

import { useSelectedShop } from '@/use/state/SelectedShop.use';

import { Shop } from '@/model/Shop.model';

interface ShopComponentProps {
    shop: Shop,
    isOpen: Boolean,
}

export default defineComponent({
    name: 'ShopResultComponent',

    components: {
        ButtonComponent,
        ShopIcon,
    },

    props: {
        shop: {
            type: Object as PropType<Shop>,
            required: true,
        },
        isOpen: Boolean,
    },

    setup(props: ShopComponentProps, context: SetupContext) {
        const router = useRouter();
        const selectedShop = useSelectedShop();

        const displayDistance = computed<string>(
            () => `${props.shop.distance.value.toFixed(1)} ${props.shop.distance.unit} away`);

        return {
            displayDistance,

            onSelect() {
                context.emit(props.isOpen ? 'close' : 'open', props.shop.name);
            },

            async onChoose() {
                selectedShop.shop.value = props.shop;
                router.push({ path: '/shop', });
            },
        }
    }
})


import { computed, defineComponent, onBeforeMount, readonly, ref, watch } from 'vue';

import PageContainerComponent from '@/component/PageContainerComponent.vue';
import ButtonComponent from '@/component/item/ButtonComponent.vue';
import ButtonGroupComponent from '@/component/item/ButtonGroupComponent.vue';
import CheckBoxComponent from '@/component/item/CheckBoxComponent.vue';
import AreYouSureModalContentComponent, { AreYouSureModalContentComponentProps } from '@/component/modal/content/AreYouSureModalContentComponent.vue';
import ChevronRightIcon from '@/component/icon/ChevronRightIcon.vue';
import NoProductFoundIcon from '@/component/icon/ExclamationCircleIcon.vue';

import { useCurrentProduct } from '@/use/state/CurrentProduct.use';
import { useScrollPosition } from '@/use/ScrollPosition.use';
import { useShoppingList } from '@/use/state/ShoppingList.use';
import { Event, eventService } from '@/service/Event.service';

export default defineComponent({
    name: 'ProductView',

    components: {
        PageContainerComponent,
        ButtonComponent,
        ButtonGroupComponent,
        CheckBoxComponent,
        ChevronRightIcon,
        NoProductFoundIcon,
    },

    setup() {
        useScrollPosition('ProductView');
        const currentProduct = useCurrentProduct();
        const shoppingList = useShoppingList();

        const product = currentProduct.product;

        const isChecked = ref<boolean>(false);

        const displayImageUrl = computed<string | null>(
            () => product.value?.imageUrl.replace('90x90', '225x225') || '');

        const displayPrice = computed<string>(
            () => `£${product.value?.price.toFixed(2)}`);

        const quantityConstraints = readonly<{ min: number, max: number, }>({
            min: 1,
            max: 1000,
        });

        const displayAddToListLabel = computed<string>(
            () => isChecked.value ? 'Remove from List' : 'Add to List');

        const displayIngredients = computed<string>(() => {
            if (!product.value || !product.value.ingredients)
                return '';

            return product.value.ingredients.join('<br>');
        });

        onBeforeMount(() => {
            if (product.value !== null)
                isChecked.value = shoppingList.isInShoppingList(product.value);

            watch(isChecked, () => {
                if (product.value === null)
                    return;

                if (isChecked.value) {
                    shoppingList.add(product.value);
                }
                else {
                    eventService.publish(Event.OPEN_MODAL, {
                        content: AreYouSureModalContentComponent,
                        props: <AreYouSureModalContentComponentProps>{
                            message: `Remove <strong>${product.value.name}</strong> from your shopping list?`,
                            yesMessage: 'Remove',
                            noMessage: 'Cancel',
                            yesAction: () => {
                                if (product.value === null)
                                    return;

                                shoppingList.remove(product.value);
                            },
                            noAction: () => {
                                isChecked.value = true;
                            },
                        },
                    });
                }
            });
        });

        return {
            product,
            isChecked,
            displayImageUrl,
            displayPrice,
            displayAddToListLabel,
            quantityConstraints,
            displayIngredients,

            onChangeQuantity(change: number) {
                if (product.value === null)
                    return;

                const prevQuantity = Number(product.value.listQuantity);
                const newQuantity = prevQuantity + change;

                if (newQuantity < quantityConstraints.min)
                    return;

                if (newQuantity > quantityConstraints.max)
                    return;

                product.value.listQuantity = newQuantity;

                shoppingList.update(product.value);
            },
        }
    },
})


import { defineComponent } from 'vue';

import LogoIcon from '@/component/icon/LogoIcon.vue';

export default defineComponent({
    name: 'LoadingComponent',

    components: {
        LogoIcon,
    },

    props: {
        label: String,
    },
})
